.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #4a1d40;
    padding: 6pt;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-wrapper {
    background-color: #f9fafb;
}

.grid-header {
    color: #4a1d40;
}

.active .MuiListItemIcon-root {
    color: 'red' !important	;
}

.MuiDataGrid-footer {
    margin-bottom: -50px;
}
.MuiTablePagination-root {
    margin-bottom: 50px;
}
.MuiDataGrid-root .MuiDataGrid-rowCount {
    margin-bottom: 50px !important;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.Dropdown-control {
    width: 160pt;
}

#tr-footer {
    height: 50px;
    width: 100%;
    background: transparent;
}

#filter-field {
    background-color: white;
    padding: 12px;
    color: #444;
}

.filter-bar p {
    font-weight: bold;
}

.filter-bar .MuiGrid-item {
    margin-right: 12px;
}
.filter-bar Dropdown-menu p {
    font-weight: normal;
}

.activity-page .Dropdown-menu .Dropdown-option {
    padding: 2px 10px;
    line-height: 0.43;
}

.Dropdown-menu .Dropdown-option.is-selected {
    background-color: #fbe1fd;
}

.Dropdown-menu .Dropdown-option:hover {
    background-color: #fbe1fd;
}

.activity-page .Dropdown-control {
    height: 44px;
    padding: 8px;
    padding-top: 0px;
}
